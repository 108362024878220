.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
}

.spinnerIcon {
    font-size: 80px;
    color: rgb(224, 224, 224);
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

/* .spinnerText {
    position: absolute;
    bottom: -50px;
    width: 300px;
    text-align: center;
    font-size: 20px;
} */