.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2500;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(2px);
}

.alertModal {
    width: 350px;
    height: 250px;
    height: fit-content;
    background-color: #a4b0be;
    border: 2px solid #747d8c;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.alertIcon {
    font-size: 50px;
    margin: 20px;
}

.contentModal {
    width: 100%;
}

.alertButton {
    padding: 5px 20px;
    margin-top: 20px;
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    
    
}