* {
  padding: 0px;
  margin: 0px;
}

.App {
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  background-color: #343a40;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
